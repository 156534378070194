<template>
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo" :hasBackButton="true" backPath="/descricao-risco">
        <template #content>
            <AppFormCadastro
                :service="service"
                :form="form"
                backPath="/descricao-risco"
                @onBeforeSave="onBeforeSave"
                @onAfterSave="onAfterSave"
                @onLoadDataEdit="onLoadDataEdit"
                @onValidate="onValidate"
            >
                <template #content>
                    <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                        <AppInfoManual nomeTelaDoManual="descricao-risco-form" />
                        <h3 class="ml-1">{{ title }}</h3>
                    </div>
                    <div class="grid formgrid">
                        <div class="field col-12 md:col-6">
                            <label for="name">Nome</label>
                            <InputText
                                id="nome"
                                v-model.trim="form.nome"
                                required="true"
                                autofocus
                                autocomplete="off"
                                :class="{ 'p-invalid': submitted && !form.nome }"
                            />
                        </div>
                    </div>
                    <div class="grid formgrid">
                        <div class="field col-12 md:col-6">
                            <label for="possivelLesao">Possíveis lesões ou agravos a saúde</label>
                            <Textarea id="possivelLesao" v-model.trim="form.possivelLesao" autofocus autocomplete="off" />
                        </div>
                        <div class="field col-12 md:col-6">
                            <label for="acoesNecessarias">Ações necessárias</label>
                            <Textarea id="acoesNecessarias" v-model.trim="form.acoesNecessarias" autofocus autocomplete="off" />
                        </div>
                    </div>
                </template>
            </AppFormCadastro>
        </template>
    </AppPanel>
</template>

<script>
import AppInfoManual from '../../components/AppInfoManual.vue';
import SesmtService from '../../services/SesmtService';
export default {
    data() {
        return {
            form: {},
            submitted: false,
            service: null
        };
    },
    created() {
        this.service = new SesmtService('/descricao-risco');
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editando descrição do risco' : 'Adicionando descrição do risco';
        }
    },
    methods: {
        async onBeforeSave() {
            this.submitted = true;
        },
        async onAfterSave() {
            this.submitted = false;
        },
        async onLoadDataEdit(data) {
            this.form = data;
        },
        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                return cbError();
            }
            return cbSuccess();
        },
        hasError() {
            if (!this.form.nome) return true;
        }
    },
    components: { AppInfoManual }
};
</script>

